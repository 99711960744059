import { LinkProps, Link as RemixLink } from "@remix-run/react";
import React from "react";

export interface InfillaLogoProps {
  linkTo?: string;
  target?: LinkProps["target"];
  size: "1" | "2";
}

export const InfillaLogo: React.FC<InfillaLogoProps> = ({
  linkTo,
  target,
  size,
}) => {
  if (linkTo) {
    return (
      <RemixLink to={linkTo} target={target}>
        <InfillaImage size={size} />
      </RemixLink>
    );
  } else {
    return <InfillaImage size={size} />;
  }
};

const InfillaImage: React.FC<{ size: InfillaLogoProps["size"] }> = ({
  size,
}) => (
  <img
    src="/images/Color_logo_220x64.png"
    alt="Infilla logo"
    width={`${DIMENSIONS[size].width}px`}
    height={`${DIMENSIONS[size].height}px`}
  />
);

const DIMENSIONS: Record<
  InfillaLogoProps["size"],
  { width: number; height: number }
> = {
  "1": { width: 55, height: 16 },
  "2": { width: 110, height: 32 },
};
